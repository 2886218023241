import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormProvider, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, Button, Stack, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  useSignUpSecondStepFormManagement,
  useSignUpFormManagement,
} from "../hooks";
import { FormTextField, BackdropLoading, FormSelectField, FormFilePhoto } from "../components";
import { MessageModal, MessageModalPhoto } from "../modals";

const keyPrefix = "forms.SignUpForm";

export const SignUpForm: FC = (): JSX.Element => {
  const { t } = useTranslation("translation", { keyPrefix });
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [modalPhoto, setModalPhoto] = useState<boolean>(true);
  const navigation = useNavigate();
  const {
    canShowNextButton,
    canShowSendButton,
    genericErr,
    loading,
    methods,
    response,
    step,
    // success,
    backStep,
    nextStep,
    submit,
  } = useSignUpFormManagement();

  const goLogin = (): void => {
    navigation("/login");
  };

  useEffect(() => {
    !!genericErr && setShowErrorMessage(true);
  }, [genericErr]);

  return (
    <>
      {!!response && (
        <MessageModal
          message={response}
          okLabel={t("modals.genericErr.ok")}
          // title={success ? t('modals.success.title') : null}
          onClickOk={goLogin}
          onClose={() => {
            setShowErrorMessage(false);
          }}
        />
      )}
      {showErrorMessage && (
        <MessageModal
          message={genericErr!}
          okLabel={t("modals.genericErr.ok")}
          onClickOk={() => {
            setShowErrorMessage(false);
          }}
          onClose={() => {
            setShowErrorMessage(false);
          }}
        />
      )}
      {loading && <BackdropLoading />}
      {step === 2 && (
        <Box position="absolute" left={18} top={21}>
          <Typography
            variant="h6"
            fontWeight="normal"
            paddingTop={4}
            sx={{ cursor: "pointer" }}
            onClick={backStep}
          >
            <ArrowBackIcon
              fontSize="small"
              sx={{ paddingTop: "4px", marginRight: "12px" }}
            />
            {t("back")}
          </Typography>
        </Box>
      )}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(submit)}>
          <Stack spacing={2} pt={3}>
            {step === 1 ? (
              <FirstStep />
            ) : (
              <SecondStep />
            )}

            <Stack direction="row" pt={4} spacing={2}>
              <Button
                type="button"
                color="secondary"
                variant="outlined"
                onClick={goLogin}
                fullWidth
              >
                {t("cancel.label")}
              </Button>
              {step === 1 ? (
                <Button
                  // type='submit'
                  onClick={nextStep}
                  variant="contained"
                  disabled={!canShowNextButton}
                  fullWidth
                >
                  {t("next.label")}
                </Button>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  disabled={!canShowSendButton}
                  fullWidth
                >
                  {t("submit.label")}
                </Button>
              )}
            </Stack>
          </Stack>
        </form>
      </FormProvider>
      {modalPhoto && (
        <MessageModalPhoto
          title={t("modals.uploadPhoto.title")}
          message={t("modals.uploadPhoto.message")}
          subMessage={t("modals.uploadPhoto.subMessage")}
          textBold={t("modals.uploadPhoto.textBold")}
          textQR={t("modals.uploadPhoto.textQR")}
          okLabel={t("modals.uploadPhoto.ok")}
          cancelLabel={t("modals.uploadPhoto.cancel")}
          onClickOk={() => {
            setModalPhoto(false);
          }}
          onClickCancel={() => {
            setModalPhoto(false);
            goLogin();
          }}
        />
      )}
    </>
  );
};

const FirstStep = (): JSX.Element => {
  const { t } = useTranslation("translation", {
    keyPrefix: `${keyPrefix}.FirstStep`,
  });

  const [selectedIdentificationType, setSelectedIdentificationType] =
    useState<String>("1");

  const { watch, setValue, clearErrors } = useFormContext();
  const watchOIdentificationType = watch("identificationType");

  const modifyForm = () => {
    setSelectedIdentificationType(watchOIdentificationType);
    setValue("identificationValue", "");
    clearErrors("identificationValue");
  };

  useEffect(
    modifyForm,
    // eslint-disable-next-line
    [watchOIdentificationType]
  );

  return (
    <>
      <FormTextField
        name="name"
        label={t("name.label")}
        inputProps={{ maxLength: 50 }}
      />
      <FormTextField
        name="firstLastName"
        label={t("firstLastName.label")}
        inputProps={{ maxLength: 50 }}
      />
      <FormTextField
        name="secondLastName"
        label={t("secondLastName.label")}
        inputProps={{ maxLength: 50 }}
      />
      <FormSelectField
        name="identificationType"
        label={t("identificationType.label")}
        inputProps={{ maxLength: 50 }}
        values={[
          { value: "1", label: t("identificationType.values.1") },
          { value: "2", label: t("identificationType.values.2") },
          { value: "3", label: t("identificationType.values.3") },
        ]}
        defaultValue="1"
      />
      <FormTextField
        name="identificationValue"
        label={t("identificationValue.label")}
        inputProps={{ maxLength: 12 }}
        onlyNumbers={selectedIdentificationType === "2"}
      />
      <FormTextField
        name="phoneNumber"
        label={t("phoneNumber.label")}
        inputProps={{ maxLength: 9 }}
        onlyNumbers
      />
      <FormTextField
        name="email"
        label={t("email.label")}
        inputProps={{ maxLength: 50 }}
      />
    </>
  );
};

const SecondStep = (): JSX.Element => {
  const { t } = useTranslation("translation", {
    keyPrefix: `${keyPrefix}.SecondStep`,
  });

  const {
    channelValues,
    departmentValues,
    districtValues,
    localsValues,
    modeSaleValues,
    modeSellerValues,
    provinceValues,
    regionValues,
    typeSellerValues,
    selectChannel,
    selectDepartment,
    selectDistrict,
    selectProvince,
    selectRegion,
  } = useSignUpSecondStepFormManagement();
  

  return (
    <>
      <FormSelectField
        name="region"
        label={t("region.label")}
        values={regionValues}
        onSelectValue={selectRegion}
      />
      <FormSelectField
        name="department"
        label={t("department.label")}
        values={departmentValues}
        onSelectValue={selectDepartment}
      />
      <FormSelectField
        name="province"
        label={t("province.label")}
        values={provinceValues}
        onSelectValue={selectProvince}
      />
      <FormSelectField
        name="district"
        label={t("district.label")}
        values={districtValues}
        onSelectValue={selectDistrict}
      />
      <FormSelectField
        name="channel"
        label={t("channel.label")}
        values={channelValues}
        onSelectValue={selectChannel}
      />
      <Box display={modeSaleValues.length > 0 ? "block" : "none"}>
        <FormSelectField
          name="modeSale"
          label={t("modeSale.label")}
          values={modeSaleValues}
          defaultValue={modeSaleValues.length > 0 ? 0 : null}
        />
      </Box>
      <Box display={modeSellerValues.length > 0 ? "block" : "none"}>
        <FormSelectField
          name="modeSeller"
          label={t("modeSeller.label")}
          values={modeSellerValues}
          defaultValue={modeSellerValues.length > 0 ? 0 : null}
        />
      </Box>
      <Box display={typeSellerValues.length > 0 ? "block" : "none"}>
        <FormSelectField
          name="typeSeller"
          label={t("typeSeller.label")}
          values={typeSellerValues}
          defaultValue={typeSellerValues.length > 0 ? 0 : null}
        />
      </Box>
      <FormSelectField
        name="localId"
        label={t("locals.label")}
        values={localsValues}
      />
      <FormFilePhoto name="photo"  />
    </>
  );
};
