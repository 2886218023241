import { useSelector } from 'react-redux';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { selectSession, selectUser } from '../../redux';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const keyPrefix = "forms.UserInfoForm";

export interface UserInfoFormFields {
  username: string;
  name: string;
  firstLastName: string;
  secondLastName: string;
  identificationType: string;
  identificationValue: string;
  phoneNumber: string;
  email: string;
  region: string;
  department: string;
  province: string;
  district: string;
  channel: string;
  salePoint: string;
  urlImage: string;
}

// const keyPrefix = 'hooks.formManagements.useUserInfoFormManagement';

export const useUserInfoFormManagement = () => {
  // const [fieldsValidationErr, setFieldsValidationErr] = useState<
  //   string | null
  // >();
  // const [genericErr, setGenericErr] = useState<string | null>();
  // const { t } = useTranslation('translation', { keyPrefix });
  const user = useSelector(selectUser);
  const { t } = useTranslation("translation", { keyPrefix });

  // const [resetPassword, { isError, error: requestErr, isSuccess }] =
  //   useResetPasswordMutation();

  const schema = yup.object().shape({
    firstLastName: yup.string(),
    // .required(t('schema.firstLastName.required'))
    // .min(4, t('schema.firstLastName.min')),
    email: yup.string(),
    // .required(t('schema.email.required'))
    // .oneOf(
    //   [yup.ref('firstLastName'), null],
    //   t('schema.email.confirmation')
    // ),
  });

  const methods = useForm<UserInfoFormFields>({
    resolver: yupResolver(schema),
    defaultValues: {
      username: user?.username,
      name: user?.name,
      firstLastName: user?.firstLastName,
      secondLastName: user?.secondLastName,
      identificationType: t(
        `identificationType.values.${user?.identificationType}`
      ),
      identificationValue: user?.identificationValue,
      phoneNumber: user?.phoneNumber,
      email: user?.email,
      region: user?.address.region.name,
      department: user?.address.department.name,
      province: user?.address.province.name,
      district: user?.address.district.name,
      salePoint: user?.salePointName,
      channel: user?.address.channel.name,
      urlImage: user?.urlImage,
    },
  });

  // useEffect(() => {
  //   if (isError) {
  //     console.error(requestErr);
  //     setGenericErr(t('errors.apiError'));
  //   }
  //   // eslint-disable-next-line
  // }, [isError]);

  // useEffect(() => {
  //   const { formState: errors } = methods;
  //   setFieldsValidationErr(
  //     errors.errors.firstLastName?.message ||
  //       errors.errors.email?.message ||
  //       null
  //   );
  //   // eslint-disable-next-line
  // }, [methods.formState.errors]);

  // useEffect(() => {
  //   isSuccess && logoutMutation(session.username!);

  //   // eslint-disable-next-line
  // }, [isSuccess]);

  const submit: SubmitHandler<UserInfoFormFields> = async fields => {
    // clearErrorMessages();
    // await resetPassword({ fields, session });
    // dispatch(savePass(fields.firstLastName));
  };

  // const clearErrorMessages = (): void => {
  //   setGenericErr(null);
  //   setFieldsValidationErr(null);
  // };

  // const logout = async (): Promise<void> => {
  //   await logoutMutation(session.username!);
  // };

  return {
    // loading,
    // fieldsValidationErr,
    // genericErr,
    methods,
    // clearErrorMessages,
    // logout,
    submit,
  };
};
