import { FC, MouseEvent, useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";

import { ModalLayout } from "../layouts";
import { Stack } from "@mui/system";
import { BackdropLoading, FormFilePhoto } from "../components";
import { FormProvider } from "react-hook-form";
import { useUploadPhotoFormManagement } from "../hooks";
import { MessageModal } from "./MessageModal";

type MessageModalPhotoProps = {
  message: string;
  textBold: string;
  textQR: string;
  okLabel: string;
  cancelLabel?: string;
  title?: string | null;
  onClickOk: () => void;
  onClickCancel?: () => void;
};

export const ModalUploadPhoto: FC<MessageModalPhotoProps> = ({
  message,
  textBold,
  textQR,
  okLabel,
  cancelLabel,
  title,
  onClickOk,
  onClickCancel,
}): JSX.Element => {
  const [showQR, setShowQR] = useState(false);
  const { success, response, loading, methods, submit } =
    useUploadPhotoFormManagement();

  const onClickButton = (e: MouseEvent<HTMLButtonElement>) => {
    methods.handleSubmit(submit)(e);
  };

  useEffect(() => {
    console.log("success", success);
    console.log("response", response);
    console.log("loading", loading);
  }, [loading, success, response]);

  return (
    <>
      {loading && <BackdropLoading />}
      {success && (
        <MessageModal
          title="Atención"
          message={response!}
          okLabel="Aceptar"
          onClickOk={onClickOk}
        />
      )}
      <ModalLayout onClose={() => {}} keepOpened>
        <FormProvider {...methods}>
          <form>
            <Stack display="grid" direction="row" zIndex={90000}>
              {title && (
                <Typography
                  variant="h5"
                  fontSize="28px"
                  textAlign="center"
                  pb={1}
                >
                  {title}
                </Typography>
              )}
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                style={{ paddingTop: 10, paddingBottom: 10 }}
              >
                <Typography fontSize="20px" style={{ paddingRight: 40 }}>
                  {message}
                </Typography>
                <FormFilePhoto name="photo" />
              </Box>
              <Typography
                fontSize="22px"
                fontWeight="bold"
                color="secondary"
                textAlign="center"
                sx={{
                  ":hover": { textDecoration: "underline" },
                  cursor: "pointer",
                }}
                onClick={() => setShowQR(true)}
              >
                {textBold}
              </Typography>
              {showQR && (
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  style={{ paddingTop: 10 }}
                >
                  <Box>
                    <img
                      width={350}
                      src="https://yy4yieeiz5.execute-api.us-east-1.amazonaws.com/qr"
                      alt="Imagen QR"
                    />
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography fontSize="16px">{textQR}</Typography>
                    <img
                      width={"70%"}
                      src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                      alt="Imagen Tienda Google Play"
                    />
                    <img
                      width={"70%"}
                      style={{ paddingLeft: 10, paddingRight: 10 }}
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Download_on_the_App_Store_Badge.svg/2560px-Download_on_the_App_Store_Badge.svg.png"
                      alt="Imagen Tienda App Store"
                    />
                    <img
                      width={"70%"}
                      style={{
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 10,
                      }}
                      src="https://static-00.iconduck.com/assets.00/app-huawei-uk-icon-512x153-qosx82ey.png"
                      alt="Imagen Tienda App Gallery"
                    />
                  </Box>
                </Box>
              )}
              <Stack direction="row" spacing={4} mt={4} justifyContent="center">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={onClickButton}
                  sx={{ minWidth: 275 }}
                  disabled={!methods.watch("photo")}
                >
                  {okLabel}
                </Button>
                {cancelLabel && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={onClickCancel}
                    sx={{ minWidth: 275 }}
                  >
                    {cancelLabel}
                  </Button>
                )}
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </ModalLayout>
    </>
  );
};
