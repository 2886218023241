import {
  Box,
  Button,
  IconButton,
  Typography
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTheme } from '@mui/material/styles';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

type Props = {
  name: string;
};

export const FormFilePhoto: React.FC<Props> = ({ name }): JSX.Element => {
  const theme = useTheme()
  const { control, clearErrors, setValue } = useFormContext();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [capturedImage, setCapturedImage] = useState<string | null>(null);
  const [isCameraActive, setIsCameraActive] = useState<boolean>(false);
  const [hasCameraPermission, setHasCameraPermission] = useState<
    boolean | null
  >(null);

  const handleClick = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
      setCapturedImage(null); // Restablecer la imagen capturada si se toma una nueva foto
      setIsCameraActive(true); // Activar la cámara
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const handleCapture = () => {
    if (canvasRef.current && videoRef.current) {
      const context = canvasRef.current.getContext("2d");
      if (context) {
        const videoWidth = videoRef.current.videoWidth;
        const videoHeight = videoRef.current.videoHeight;
  
        // Calcular el tamaño del recorte para mantener la relación de aspecto 1:1
        const size = Math.min(videoWidth, videoHeight);
        const xOffset = (videoWidth - size) / 2;
        const yOffset = (videoHeight - size) / 2;
  
        // Configurar el tamaño del canvas para que sea cuadrado
        canvasRef.current.width = size;
        canvasRef.current.height = size;
  
        context.drawImage(
          videoRef.current,
          xOffset,
          yOffset,
          size,
          size,
          0,
          0,
          size,
          size
        );
  
        const dataUrl = canvasRef.current.toDataURL("image/png");
        setCapturedImage(dataUrl);
        const file = dataURLtoFile(dataUrl, "profile-photo.png");
        clearErrors(name);
        setValue(name, file);
  
        const stream = videoRef.current.srcObject as MediaStream;
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
        videoRef.current.srcObject = null;
        setIsCameraActive(false);
      }
    }
  };  

  const handleCancelCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject as MediaStream;
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }
    setIsCameraActive(false);
  };

  const checkCameraPermission = async () => {
    try {
      // Verifica si los permisos ya han sido otorgados
      const permissions = await navigator.permissions.query({
        name: "camera" as PermissionName,
      });
      const hasCameraPermission = permissions.state === "granted";

      if (hasCameraPermission) {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const hasCamera = devices.some(
          (device) => device.kind === "videoinput"
        );
        setHasCameraPermission(hasCamera);
      } else {
        setHasCameraPermission(false);
      }
    } catch (error) {
      console.error("Error checking camera permissions:", error);
      setHasCameraPermission(false);
    }
  };

  const dataURLtoFile = (dataUrl: string, filename: string) => {
    const arr = dataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)![1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  useEffect(() => {
    checkCameraPermission();
  }, []);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange }, fieldState: { error } }) => {
        
        return (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography
              variant="h6"
              gutterBottom
              color={!!error ? "error": "GrayText"}
            >
              TOMARSE FOTO
            </Typography>
            {/* <input
              type="file"
              accept="image/*"
              onChange={(e) => {
                const file = e.target.files?.[0];
                onChange(file); // Pasar el archivo al campo controlado
              }}
              style={{ display: "none" }}
              // ref={(inputRef) => inputRef?.click()} // Auto clic en el input para abrir el diálogo
            /> */}
            <Box
              sx={{
                borderRadius: "50%",
                border: "2px solid",
                borderColor: !!error ? theme.palette.error.main : "GrayText",
                padding: 0,
                width: 200,
                height: 200,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <video
                ref={videoRef}
                style={{
                  display: capturedImage ? "none" : "block",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              ></video>
              <canvas
                ref={canvasRef}
                style={{ display: "none", width: 200, height: 200 }}
              ></canvas>
              {capturedImage ? (
                <img
                  src={capturedImage}
                  alt="Captured"
                  style={{ width: 200, height: 200, borderRadius: "50%" }}
                />
              ) : (
                <>
                  {!isCameraActive && (
                    <IconButton
                      onClick={handleClick}
                      sx={{
                        borderRadius: "50%",
                        padding: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        overflow: "hidden",
                      }}
                      style={{
                        color: !!error ? theme.palette.error.main :"GrayText",
                        position: "absolute",
                      }}
                    >
                      <CloudUploadIcon sx={{ fontSize: 50 }} />
                    </IconButton>
                  )}
                </>
              )}
            </Box>
            {capturedImage && (
              <Button
                onClick={handleClick}
                variant="contained"
                color="secondary"
                sx={{ mt: 2 }}
              >
                Tomar otra foto
              </Button>
            )}
            {isCameraActive && (
              <>
                <Button
                  onClick={handleCapture}
                  variant="contained"
                  color="secondary"
                  sx={{ mt: 2 }}
                >
                  Capturar Foto
                </Button>
                <Button
                  onClick={handleCancelCamera}
                  variant="outlined"
                  color="secondary"
                  sx={{ mt: 2 }}
                >
                  Cancelar
                </Button>
              </>
            )}
            <Typography
              variant="h6"
              gutterBottom
              color={!!error ? "error" : "GrayText"}
            >
              {!!error ? error.message : ""}
              {/* {String(photoError.message)} */}
            </Typography>
          </Box>
        );
      }}
    />
  );
};
