import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  selectPropertiesApp,
  selectSession,
  updateUserImage,
  useGetPropertiesQuery,
  useUpdateUserMutation,
} from "../../redux";
import { useDispatch, useSelector } from "react-redux";

interface ResponseData {
  result: {
    responseCode: number;
    responseMessage: string;
  };
  urlImage: string;
}

interface ResponseService {
  data: ResponseData;
}

export interface UpdatePhotoFormFields {
  photo: File | null;
}

const keyPrefix = "hooks.formManagements.useSignUpFormManagement";

const defaultValues: UpdatePhotoFormFields = {
  photo: null,
};

export const useUploadPhotoFormManagement = () => {
  const dispatch = useDispatch();
  const [genericErr, setGenericErr] = useState<string | null>();
  const [loading, setLoading] = useState<boolean | null>(null);
  const [success, setSuccess] = useState<boolean | null>(null);
  const [response, setResponse] = useState<string | null>(null);
  const { t } = useTranslation("translation", { keyPrefix });
  const session = useSelector(selectSession);
  const properties = useSelector(selectPropertiesApp);
  const updateImageUserUrl = properties["url.service.updateImageUser"];
  // const validDocumentUrl = properties["url.service.validdoc"];

  useGetPropertiesQuery({});
  const [updateUser, { isError, error: requestErr }] =
    useUpdateUserMutation();

  const schema = yup.object().shape({
    photo: yup.mixed().required(t("schema.photo.required")),
  });

  const methods = useForm<UpdatePhotoFormFields>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    if (isError) {
      console.error(requestErr);
      setGenericErr(t("errors.apiError"));
    }
    // eslint-disable-next-line
  }, [isError]);

  const submit: SubmitHandler<UpdatePhotoFormFields> = async ({ photo }) => {
    setLoading(true);
    clearErrorMessages();

    // console.log("registerData object", registerData);

    const res: ResponseService = (await updateUser({
      params: { userId: session.userId! },
      file: photo!,
      url: updateImageUserUrl.replace(/^http:/, 'https:'),
    })) as ResponseService;

    !!res && setLoading(false);

    if (!!res) {
      console.log("res", res);

      const {
        result: { responseCode, responseMessage },
        urlImage
      } = res.data as ResponseData;

      setSuccess(responseCode === 200);
      setResponse(responseMessage);
      responseCode === 200 && dispatch(updateUserImage(urlImage))
    }
  };

  const clearErrorMessages = (): void => {
    setGenericErr(null);
    setSuccess(null);
    setResponse(null);
  };

  return {
    genericErr,
    loading,
    methods,
    response,
    success,
    submit,
  };
};
