import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import sha256 from "crypto-js/sha256";

import {
  authApi,
  fcmTokensApi,
  incentivesApi,
  promosApi,
  propertiesApi,
  reportsApi,
  resetPasswordApi,
} from "../services";
import { RootState } from "../store";
import { Session, User, APIBaseResponse } from "../../types";

export interface AuthState {
  isAuth: boolean;
  isBlocked: boolean;
  authError: string | null;
  token: string | null;
  user: User | null;
  restorePassword: boolean;
  hashedPass: string | null;
  loginAttempts: number | null;
  responseCode: number | null;
}

const initialState: AuthState = {
  isAuth: false,
  isBlocked: false,
  authError: null,
  token: null,
  user: null,
  restorePassword: false,
  hashedPass: null,
  loginAttempts: null,
  responseCode: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    savePass: (state, action: PayloadAction<string | null>) => {
      const pass = action.payload;
      if (pass === null) state.hashedPass = null;
      else state.hashedPass = sha256(pass).toString();
    },
    updateUserImage: (state, action) => {
      const newUrlImage = action.payload;
      if (state.user) {
        state.user.urlImage = newUrlImage;
      }
    },
    clearLoginData: (state) => {
      state.authError = null;
      state.loginAttempts = null;
      state.responseCode = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authApi.endpoints.logout.matchPending, (state, _) => {
        state.isAuth = false;
        state.isBlocked = false;
        state.authError = null;
        state.token = null;
        state.user = null;
        state.restorePassword = false;
        state.hashedPass = null;
      })
      .addMatcher(authApi.endpoints.login.matchPending, (state) => {
        state.authError = null;
        state.loginAttempts = null;
        state.responseCode = null;
        state.isBlocked = false;
        state.isAuth = false;
        state.restorePassword = false;
        state.user = null;
      })
      .addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
        const {
          result: { responseCode, responseMessage },
          attempts,
        } = action.payload;

        console.log('ATTEMPS', attempts);
        

        state.loginAttempts = attempts >= 0 ? attempts : null;
        state.responseCode = responseCode;

        switch (responseCode) {
          case 205:
            state.isAuth = false;
            state.isBlocked = false;
            state.authError = responseMessage;
            state.token = null;
            state.user = null;
            break;
          case 207:
            state.isAuth = false;
            state.isBlocked = false;
            state.authError = responseMessage;
            state.token = null;
            state.user = null;
            break;
          case 208:
            state.isAuth = false;
            state.isBlocked = false;
            state.authError = responseMessage;
            state.token = null;
            state.user = null;
            break;
          case 209:
            state.isAuth = false;
            state.isBlocked = true;
            state.authError = responseMessage;
            state.token = null;
            state.user = null;
            state.loginAttempts = null;
            break;
          default:
            const {
              appInfo: { sessionToken },
              user,
            } = action.payload;

            state.isAuth = true;
            state.isBlocked = false;
            state.authError = null;
            state.token = sessionToken;
            state.user = user;
            state.restorePassword = user.restorePassword;
            state.loginAttempts = null;
        }
      })
      .addMatcher(
        resetPasswordApi.endpoints.resetPassword.matchFulfilled,
        (state, action) => {
          const {
            result: { responseCode, responseMessage },
          } = action.payload;

          if (responseCode === 200) {
            state.restorePassword = false;
          } else {
            console.error(responseMessage);
          }
        }
      )
      //Validate status 310 in all backend requests
      .addMatcher(
        promosApi.endpoints.getPromos.matchFulfilled,
        (state, action: PayloadAction<APIBaseResponse>) => {
          const { responseCode } = action.payload.result;
          if (responseCode === 310) {
            console.error("status code 310", action.payload.result);
            state.isAuth = false;
            state.authError = null;
            state.token = null;
            state.user = null;
            state.restorePassword = false;
            state.hashedPass = null;
          }
        }
      )
      .addMatcher(
        propertiesApi.endpoints.getProperties.matchFulfilled,
        (state, action: PayloadAction<APIBaseResponse>) => {
          const { responseCode } = action.payload.result;
          if (responseCode === 310) {
            console.error("status code 310", action.payload.result);
            state.isAuth = false;
            state.authError = null;
            state.token = null;
            state.user = null;
            state.restorePassword = false;
            state.hashedPass = null;
          }
        }
      )
      .addMatcher(
        resetPasswordApi.endpoints.resetPassword.matchFulfilled,
        (state, action: PayloadAction<APIBaseResponse>) => {
          const { responseCode } = action.payload.result;
          if (responseCode === 310) {
            console.error("status code 310", action.payload.result);
            state.isAuth = false;
            state.authError = null;
            state.token = null;
            state.user = null;
            state.restorePassword = false;
            state.hashedPass = null;
          }
        }
      )
      .addMatcher(
        fcmTokensApi.endpoints.registerToken.matchFulfilled,
        (state, action: PayloadAction<APIBaseResponse>) => {
          const { responseCode } = action.payload.result;
          if (responseCode === 310) {
            console.error("status code 310", action.payload.result);
            state.isAuth = false;
            state.authError = null;
            state.token = null;
            state.user = null;
            state.restorePassword = false;
            state.hashedPass = null;
          }
        }
      )
      .addMatcher(
        incentivesApi.endpoints.getIncentives.matchFulfilled,
        (state, action: PayloadAction<APIBaseResponse>) => {
          const { responseCode } = action.payload.result;
          if (responseCode === 310) {
            console.error("status code 310", action.payload.result);
            state.isAuth = false;
            state.authError = null;
            state.token = null;
            state.user = null;
            state.restorePassword = false;
            state.hashedPass = null;
          }
        }
      )
      .addMatcher(
        reportsApi.endpoints.reportActivity.matchFulfilled,
        (state, action: PayloadAction<APIBaseResponse>) => {
          const { responseCode } = action.payload.result;
          if (responseCode === 310) {
            console.error("status code 310", action.payload.result);
            state.isAuth = false;
            state.authError = null;
            state.token = null;
            state.user = null;
            state.restorePassword = false;
            state.hashedPass = null;
          }
        }
      )
      .addMatcher(
        reportsApi.endpoints.reportContentDownloaded.matchFulfilled,
        (state, action: PayloadAction<APIBaseResponse>) => {
          const { responseCode } = action.payload.result;
          if (responseCode === 310) {
            console.error("status code 310", action.payload.result);
            state.isAuth = false;
            state.authError = null;
            state.token = null;
            state.user = null;
            state.restorePassword = false;
            state.hashedPass = null;
          }
        }
      )
      .addMatcher(
        reportsApi.endpoints.reportContentWatched.matchFulfilled,
        (state, action: PayloadAction<APIBaseResponse>) => {
          const { responseCode } = action.payload.result;
          if (responseCode === 310) {
            console.error("status code 310", action.payload.result);
            state.isAuth = false;
            state.authError = null;
            state.token = null;
            state.user = null;
            state.restorePassword = false;
            state.hashedPass = null;
          }
        }
      )
      .addMatcher(
        reportsApi.endpoints.reportIncentive.matchFulfilled,
        (state, action: PayloadAction<APIBaseResponse>) => {
          const { responseCode } = action.payload.result;
          if (responseCode === 310) {
            console.error("status code 310", action.payload.result);
            state.isAuth = false;
            state.authError = null;
            state.token = null;
            state.user = null;
            state.restorePassword = false;
            state.hashedPass = null;
          }
        }
      );
  },
});

export const { savePass, updateUserImage, clearLoginData } = authSlice.actions;

export const authReducer = authSlice.reducer;

export const selectAuthError = (state: RootState) => state.auth.authError;
export const selectHashedPassword = (state: RootState) => state.auth.hashedPass;
// export const selectPassword = (state: RootState) => state.auth.passUser;
export const selectIsBlockedUser = (state: RootState) => state.auth.isBlocked;
export const selectIsAuthenticated = (state: RootState) => state.auth.isAuth;
export const selectRequireResetPassword = (state: RootState) =>
  state.auth.restorePassword;
export const selectLoginAttempts = (state: RootState) => state.auth.loginAttempts;
export const selectResponseCode = (state: RootState) => state.auth.responseCode;
export const selectUser = (state: RootState) => state.auth.user;
export const selectSession = (state: RootState): Session => ({
  sessionToken: state.auth.token,
  userId: state.auth.user?.id,
  username: state.auth.user?.username,
  name: state.auth.user?.name,
  firstLastName: state.auth.user?.firstLastName,
  channel: state.auth.user?.address.channel.name!,
  email: state.auth.user?.email!,
  urlImage: state.auth.user?.urlImage
});
