import { FC, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import { ModalLayout } from "../layouts";
import { Stack } from "@mui/system";

type MessageModalPhotoProps = {
  message: string;
  subMessage: string;
  textBold: string;
  textQR: string;
  okLabel: string;
  cancelLabel: string;
  showOkIcon?: boolean;
  title?: string | null;
  onClickOk: () => void;
  onClickCancel: () => void;
};

export const MessageModalPhoto: FC<MessageModalPhotoProps> = ({
  message,
  subMessage,
  textBold,
  textQR,
  okLabel,
  cancelLabel,
  showOkIcon = false,
  title,
  onClickOk,
  onClickCancel,
}): JSX.Element => {
  const [showQR, setShowQR] = useState(false);
  return (
    <ModalLayout onClose={() => {}} keepOpened>
      <Stack display="grid" direction="row" zIndex={90000}>
        {showOkIcon && (
          <CheckCircleOutlineIcon
            color="success"
            sx={{ fontSize: "54px", marginBottom: 4 }}
          />
        )}
        {title && (
          <Typography variant="h5" fontSize="28px" pb={1}>
            {title}
          </Typography>
        )}
        <Typography fontSize="22px">{message}</Typography>
        <Typography fontSize="22px">{subMessage}</Typography>
        <Typography
          fontSize="22px"
          fontWeight="bold"
          color="secondary"
          textAlign="center"
          sx={{ ":hover": { textDecoration: "underline" }, cursor: "pointer" }}
          onClick={() => setShowQR(true)}
        >
          {textBold}
        </Typography>
        {showQR && (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            style={{ paddingTop: 10 }}
          >
            <Box>
              <img
                width={350}
                src="https://yy4yieeiz5.execute-api.us-east-1.amazonaws.com/qr"
                alt="Imagen QR"
              />
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography fontSize="16px">{textQR}</Typography>
              <img
                width={"70%"}
                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                alt="Imagen Tienda Google Play"
              />
              <img
                width={"70%"}
                style={{ paddingLeft: 10, paddingRight: 10 }}
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Download_on_the_App_Store_Badge.svg/2560px-Download_on_the_App_Store_Badge.svg.png"
                alt="Imagen Tienda App Store"
              />
              <img
                width={"70%"}
                style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 10 }}
                src="https://static-00.iconduck.com/assets.00/app-huawei-uk-icon-512x153-qosx82ey.png"
                alt="Imagen Tienda App Gallery"
              />
            </Box>
          </Box>
        )}
        <Stack direction="row" spacing={4} mt={4} justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={onClickOk}
            sx={{ minWidth: 275 }}
          >
            {okLabel}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={onClickCancel}
            sx={{ minWidth: 275 }}
          >
            {cancelLabel}
          </Button>
        </Stack>
      </Stack>
    </ModalLayout>
  );
};
