import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Div100vh from "react-div-100vh";
import { useTranslation } from "react-i18next";
import Notification from "react-web-notification";
import { useNavigate } from "react-router-dom";

import {
  BackdropLoading,
  //  BackdropLoading,
  Menu,
} from "../components";
import {
  useLoadPropertiesAndPromos,
  useNotification,
  useRequestContent,
} from "../hooks";
import { selectActivePage, selectSession } from "../redux";
import {
  HomePage,
  ProductPage,
  MagazinePage,
  VideoPage,
  SearchPage,
  ReactionsPage,
  FavoritesPage,
  AboutPage,
  FAQPage,
  ReportPage,
  SuggestionsPage,
  TCPage,
  ChangePasswordPage,
  UserInfoPage,
} from "../pages";
import { VendamosNotification } from "../types";
import { MessageModal, ModalUploadPhoto } from "../modals";

const keyPrefix = "layouts.AppLayout";

export const AppLayout: FC = (): JSX.Element => {
  // const { loading } = useLoadPropertiesAndPromos();
  useLoadPropertiesAndPromos();
  const { needToWait, availability, restoreAvailability } = useRequestContent();
  const { activePage, searchingContent } = useSelector(selectActivePage);
  const { notifications, removeNotification } = useNotification();
  const session = useSelector(selectSession);
  const [visible, setVisible] = useState(false);
  const [modalPhoto, setModalPhoto] = useState(false);
  const { t } = useTranslation("translation", { keyPrefix });
  const navigate = useNavigate();

  const handleOnShow = ({ tag }: VendamosNotification): void => {
    navigate(`/?nt=${tag}`);
  };

  useEffect(() => {
    setVisible(!session.urlImage);
  }, []);

  if (needToWait) {
    return <BackdropLoading />;
  }

  return (
    <Div100vh>
      {availability === false && (
        <MessageModal
          okLabel={t("modals.genericErr.ok")}
          message={t("modals.availability.message")}
          // okLabel={t('modals.genericErr.ok')}
          onClickOk={restoreAvailability}
          onClose={restoreAvailability}
        />
      )}
      {visible && (
        <MessageModal
          okLabel={t("modals.photoRequired.ok")}
          message={t("modals.photoRequired.message")}
          // okLabel={t('modals.genericErr.ok')}
          onClickOk={() => {
            setVisible(false);
            setModalPhoto(true);
          }}
          // onClose={() => {}}
        />
      )}

      {modalPhoto && (
        <ModalUploadPhoto
          title={t("modals.uploadPhoto.title")}
          message={t("modals.uploadPhoto.message")}
          textBold={t("modals.uploadPhoto.textBold")}
          textQR={t("modals.uploadPhoto.textQR")}
          okLabel={t("modals.uploadPhoto.ok")}
          onClickOk={() => {
            setModalPhoto(false);
          }}
        />
      )}
      {notifications.map((notification, idx) => {
        const { title, body, image } = notification;
        return (
          <Notification
            key={idx}
            //  ignore={off}
            title={title!}
            timeout={15000}
            onShow={() => {
              removeNotification(notification);
            }}
            onClick={() => {
              handleOnShow(notification);
            }}
            options={{
              body,
              // icon: '/logo_favicon.png',
              icon: image,
            }}
          />
        );
      })}
      <Menu />

      {!!searchingContent ? (
        <SearchPage />
      ) : (
        {
          home: <HomePage allowIncentive={availability === null} />,
          userInfo: <UserInfoPage />,
          changePassword: <ChangePasswordPage />,
          favorites: <FavoritesPage />,
          reactions: <ReactionsPage />,
          products: <ProductPage />,
          magazine: <MagazinePage />,
          video: <VideoPage />,
          //Help section
          about: <AboutPage />,
          faq: <FAQPage />,
          report: <ReportPage />,
          suggest: <SuggestionsPage />,
          tc: <TCPage />,
        }[activePage]
      )}
    </Div100vh>
  );
};
