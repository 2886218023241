import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  selectPropertiesApp,
  useGetPropertiesQuery,
  useRegisterUserMutation,
  useValidateDocumentMutation,
} from "../../../redux";
import { APIBaseResponse, DataUser } from "../../../types";
import { useSelector } from "react-redux";

export interface SignUpFormFields {
  name: string;
  firstLastName: string;
  secondLastName: string;
  identificationType: number;
  identificationValue: string;
  phoneNumber: string;
  email: string;
  region: number | null;
  department: number | null;
  province: number | null;
  district: number | null;
  channel: number | null;
  modeSale: number | null;
  modeSeller: number | null;
  typeSeller: number | null;
  localId: number | null;
  photo: File | null;
}

const keyPrefix = "hooks.formManagements.useSignUpFormManagement";

const defaultValues: SignUpFormFields = {
  name: "",
  firstLastName: "",
  secondLastName: "",
  identificationType: 1,
  identificationValue: "",
  phoneNumber: "",
  email: "",
  region: null,
  department: null,
  province: null,
  district: null,
  channel: null,
  modeSale: null,
  modeSeller: null,
  typeSeller: null,
  localId: null,
  photo: null,
};

export const useSignUpFormManagement = () => {
  const [canShowNextButton, setCanShowNextButton] = useState<boolean>(false);
  const [canShowSendButton, setCanShowSendButton] = useState<boolean>(false);
  const [genericErr, setGenericErr] = useState<string | null>();
  const [step, setStep] = useState<number>(1);
  const [success, setSuccess] = useState<boolean | null>(null);
  const [response, setResponse] = useState<string | null>(null);
  const { t } = useTranslation("translation", { keyPrefix });
  const properties = useSelector(selectPropertiesApp);
  const registerUrl = properties["url.service.registerV2"];
  // const validDocumentUrl = properties["url.service.validdoc"];

  useGetPropertiesQuery({});
  const [validateDocumentMutation] = useValidateDocumentMutation();
  const [registerUser, { isError, isLoading, error: requestErr, data }] =
    useRegisterUserMutation();

  const schema = yup.object().shape({
    name: yup.string().required(t("schema.name.required")),
    firstLastName: yup.string().required(t("schema.firstLastName.required")),
    secondLastName: yup.string().required(t("schema.secondLastName.required")),
    identificationValue: yup
      .string()
      .required(t("schema.identificationValue.required"))
      .when("identificationType", (identificationType, schema) => {
        // console.log('identificationType', identificationType);
        switch (identificationType) {
          case "2":
            return schema
              .min(8, t("schema.identificationValue.dni"))
              .max(8, t("schema.identificationValue.dni"));
          case "3":
            return schema
              .min(4, t("schema.identificationValue.ce"))
              .max(12, t("schema.identificationValue.ce"));
          default:
            return schema
              .min(4, t("schema.identificationValue.codEmp"))
              .max(10, t("schema.identificationValue.codEmp"));
        }
      }),
    phoneNumber: yup
      .string()
      .required(t("schema.phoneNumber.required"))
      .min(9, t("schema.phoneNumber.len"))
      .max(9, t("schema.phoneNumber.len")),
    email: yup
      .string()
      .email(t("schema.email.format"))
      .required(t("schema.email.required")),
    region: yup.number().typeError(t("schema.commons.required")),
    department: yup.number().typeError(t("schema.commons.required")),
    province: yup.number().typeError(t("schema.commons.required")),
    district: yup.number().typeError(t("schema.commons.required")),
    channel: yup.number().typeError(t("schema.commons.required")),
    modeSale: yup
      .number()
      .nullable()
      .test(
        "validate-modeSale-regquired",
        "modeSale-validator",
        (value, ctx) => {
          const { createError } = ctx;
          if (value !== null && value !== undefined && value === 0) {
            return createError({
              message: t("schema.commons.required"),
            });
          }
          return true;
        }
      ),
    modeSeller: yup
      .number()
      .nullable()
      .test(
        "validate-modesaller-regquired",
        "modeseleer-validator",
        (value, ctx) => {
          const { createError } = ctx;
          if (value !== null && value !== undefined && value === 0) {
            return createError({
              message: t("schema.commons.required"),
            });
          }
          return true;
        }
      ),
    typeSeller: yup
      .number()
      .nullable()
      .test(
        "validate-typeSeller-regquired",
        "typeSeller-validator",
        (value, ctx) => {
          const { createError } = ctx;
          if (value !== null && value !== undefined && value === 0) {
            return createError({
              message: t("schema.commons.required"),
            });
          }
          return true;
        }
      ),
    localId: yup.number().typeError(t("schema.commons.required")),
    photo: yup.mixed().required(t("schema.photo.required")),
  });

  const methods = useForm<SignUpFormFields>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  // console.log('propertiesData', propertiesData);

  const fieldHasNoErrorAndIsDirty = (fieldName: string): boolean => {
    const {
      formState: { dirtyFields, errors },
    } = methods;

    type DirtyObjectKey = keyof typeof dirtyFields;
    type ErrorObjectKey = keyof typeof errors;

    const isDirty = dirtyFields[fieldName as DirtyObjectKey];
    if (isDirty === undefined) return false;

    const hasError = errors[fieldName as ErrorObjectKey];

    return !hasError && isDirty;
  };

  const firstStepIsValid = async (): Promise<boolean> => {
    return (
      fieldHasNoErrorAndIsDirty("name") &&
      fieldHasNoErrorAndIsDirty("firstLastName") &&
      fieldHasNoErrorAndIsDirty("secondLastName") &&
      // fieldHasNoErrorAndIsDirty('identificationType') &&
      fieldHasNoErrorAndIsDirty("identificationValue") &&
      fieldHasNoErrorAndIsDirty("phoneNumber") &&
      fieldHasNoErrorAndIsDirty("email")
    );
  };

  const validateFirstStepIsDirty = (): boolean => {
    const {
      formState: { dirtyFields },
    } = methods;

    return (
      !!dirtyFields["name"] ||
      !!dirtyFields["firstLastName"] ||
      !!dirtyFields["secondLastName"] ||
      !!dirtyFields["identificationType"] ||
      !!dirtyFields["identificationValue"] ||
      !!dirtyFields["phoneNumber"] ||
      !!dirtyFields["email"]
    );
  };

  const validateSecondStepIsDirty = (): boolean => {
    const {
      formState: { dirtyFields },
    } = methods;

    // console.log('dirtyFields[region]', dirtyFields['region']);
    const photoIsPresent = methods.watch("photo") !== undefined;

    return (
      !!dirtyFields["region"] ||
      !!dirtyFields["department"] ||
      !!dirtyFields["province"] ||
      !!dirtyFields["district"] ||
      (!!dirtyFields["channel"] && photoIsPresent)
    );
  };

  const isValidDocument = async (): Promise<boolean> => {
    const type = methods.getValues("identificationType");
    const value = methods.getValues("identificationValue");
    const valueIsValid = !methods.getFieldState("identificationValue").error;

    if (!!valueIsValid) {
      const res = (await validateDocumentMutation({ type, value })) as {
        data: APIBaseResponse;
      };
      if (res.data.result.responseCode === 200) {
        return true;
      } else {
        methods.setError("identificationValue", {
          message: t("schema.identificationValue.notValid"),
        });
      }
    }
    return false;
  };

  useEffect(() => {
    if (isError) {
      console.error(requestErr);
      setGenericErr(t("errors.apiError"));
    }
    // eslint-disable-next-line
  }, [isError]);

  useEffect(() => {
    if (step === 1) {
      const firstStepIsDirty = validateFirstStepIsDirty();
      setCanShowNextButton(firstStepIsDirty);
    }
    // eslint-disable-next-line
  }, [methods.formState.dirtyFields]);

  useEffect(() => {
    if (step === 2) {
      // console.log('methods.formState', methods.formState);
      const secondStepIsDirty = validateSecondStepIsDirty();
      setCanShowSendButton(secondStepIsDirty);
    }
    // eslint-disable-next-line
  }, [methods.formState]);

  useEffect(() => {
    if (!data) return;

    const {
      result: { responseCode, responseMessage },
    } = data;

    setSuccess(responseCode === 200);
    setResponse(responseMessage);
  }, [data]);

  const submit: SubmitHandler<SignUpFormFields> = async ({
    name,
    firstLastName,
    secondLastName,
    identificationType,
    identificationValue,
    phoneNumber,
    email,
    localId,
    modeSale,
    typeSeller,
    modeSeller,
    channel,
    photo,
  }) => {
    clearErrorMessages();

    const registerData = {
      name,
      firstLastName,
      secondLastName,
      identificationType,
      identificationValue,
      phoneNumber,
      email,
      localId,
      ...(modeSale === null ? {} : { modeSaleId: modeSale }),
      ...(typeSeller === null ? {} : { typeSellerId: typeSeller }),
      ...(modeSeller === null ? {} : { modeSellerId: modeSeller }),
      ...(channel === -1 ? {} : { channelId: channel }),
    } as DataUser;

    console.log("registerData object", registerData);
    console.log("registerUrl", registerUrl);
    

    await registerUser({
      params: registerData,
      file: photo!,
      url: registerUrl.replace(/^http:/, 'https:'),
    });
  };

  const backStep = () => {
    setStep(1);
  };

  const nextStep = async (): Promise<void> => {
    const { trigger: triggerAllFields } = methods;
    await triggerAllFields();
    const stepIsValid = await firstStepIsValid();
    const documentIsValid = await isValidDocument();
    if (stepIsValid && documentIsValid) {
      setStep(2);
      methods.clearErrors();
    }
  };

  const clearErrorMessages = (): void => {
    setGenericErr(null);
    setSuccess(null);
    setResponse(null);
  };

  return {
    canShowNextButton,
    canShowSendButton,
    genericErr,
    loading: isLoading,
    methods,
    response,
    step,
    success,
    clearErrorMessages,
    backStep,
    nextStep,
    submit,
  };
};
