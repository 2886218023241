import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL } from '../../config';
import { DataUser, ParamsUserUpdate, RegisterUserParams, UpdateUserParams } from '../../types';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: builder => ({
    registerUser: builder.mutation({
      query: ({ params, file, url }: RegisterUserParams) => {

        const formData = new FormData();
        formData.append('file', file);
        (Object.keys(params) as Array<keyof DataUser>).forEach(key => {
          const value = params[key];
          if (value !== null && value !== undefined) {
            formData.append(key, value.toString());
          }
        });

        return {
          url,
          method: 'POST',
          body: formData,
        };
      },
    }),
    updateUser: builder.mutation({
      query: ({ params, file, url }: UpdateUserParams) => {

        const formData = new FormData();
        formData.append('file', file);
        (Object.keys(params) as Array<keyof ParamsUserUpdate>).forEach(key => {
          const value = params[key];
          if (value !== null && value !== undefined) {
            formData.append(key, value.toString());
          }
        });

        return {
          url,
          method: 'POST',
          body: formData,
        };
      },
    }),
  }),
});

export const { useRegisterUserMutation, useUpdateUserMutation } = userApi;
